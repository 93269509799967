<template>
  <div>
    <div style="display: flex;margin-top: 80%">
      <el-input v-model="tel" placeholder="请输入手机号"/>
      <el-button type="primary" @click="yz_tel">验证</el-button>
    </div>
  </div>
</template>

<script>
import {Encrypt} from "@/utils/secret";
import {ElMessage} from "element-plus";

export default {
  name: "wx_bang_tel_exam",
  data(){
    return{
      tel: ''
    }
  },
  created() {

  },
  methods:{
    yz_tel(){
      const tel = Encrypt(this.tel);
      this.axios.post('/userinfo/UpFoodNumByTle', (response) =>{
        if (!response.obj){
          ElMessage.error('手机号未录入系统，请联系科信部!')
        }else {
          window.location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd4ab000b742db3e5&redirect_uri=https://www.cr12cz.cn/wx_message_exam&response_type=code&scope=snsapi_base&state=' + response.obj.id;
        }
      },{
        tel: tel
      })
    }
  }
}
</script>

<style scoped>

</style>
